import React from 'react'
import { connectStore } from 'redux-box'
import { ThemeButton } from '../../../../static/styled/custom.styled'
import './unlink.css'
import { module as ui } from "@st/ui";
import { module as user } from "@st/user";

const UnlinkSchoolCode = (props) => {
    const handleUnlinkSchool = () => {
        const { user } = props;
        let data = {
            school: user.active_child.school_code,
            child: user.active_child.id,
        };
        user.unlinkSchoolCode(data);
        props.ui.hideModal()
    }
    return (
        <div className='unlink_school_code_modal'>
            <p>Are you sure you want to unlink the school from this child profile?</p>
            <div className='unlink_btn_container'>
                <ThemeButton onClick={() => handleUnlinkSchool()} primary style={{ width: '50%' }} >UnLink</ThemeButton>
                <ThemeButton onClick={() => props.ui.hideModal()} style={{ width: '50%' }} >Cancel</ThemeButton>
            </div>

        </div>
    )
}

export default connectStore({
    ui, user
})(UnlinkSchoolCode)